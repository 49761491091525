<template>
  <div class="v_bind_account_list g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>{{titleName}}</span>
      </template>
    </head-back>

    <ul class="v-bind-account-list" v-show="accoutList.length">
      <li class="v-bind-account-item g-flex-align-start" v-for="(item, index) in accoutList" :key="index" @click="itemClick(item, index)">
        <!-- 左侧 -->
        <div class="v-bind-account-item-left">
          <i class="iconfont icon-xianshi_xuanzetianchong" v-show="selectIndex === index"></i>
          <i class="iconfont icon-yuancircle46" v-show="selectIndex !== index"></i>
        </div>
        <!-- 右侧 -->
        <div class="v-bind-account-item-right">
          <!-- 淘宝用户名 -->
          <div class="v-bind-account-item-top g-flex-align-center">
            <div class="v-bind-account-item-top-left">{{item.account}}</div> 
            <div class="v-bind-account-item-top-right" v-show="item.status === 0">审核未通过</div>
            <div class="v-bind-account-item-top-right" v-show="item.status === 1">审核中</div>
            <div class="v-bind-account-item-top-right"  v-show="item.status === 2">审核通过</div>
          </div>
          <!-- 底部这一块 -->
          <div class="v-bind-account-item-bottom g-flex-align-center">
            <!-- 收货地址手机号码 -->
            <div class="v-bind-account-item-bottom-left g-flex-column">
              <p class="v-bind-account-item-bottom-left-address g-flex-align-center">收货地址:<span>{{item.region}} {{item.address}}</span></p>
              <p class="v-bind-account-item-bottom-left-tel g-flex-align-center">手机号码:<span>{{item.mobile}}</span></p>
              <p class="v-bind-account-item-bottom-left-name g-flex-align-center">联系人:<span>{{item.name}}</span></p>
            </div>
            <!-- 编辑 -->
            <div class="v-bind-account-item-bottom-right" @click.stop="editClick(item)">
              <i class="iconfont icon-edit-fill"></i>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="v-bind-account-no-list g-flex-column g-flex-align-center g-flex-justify-center" v-show="!accoutList.length">
      <img src="/image/no-account-list.png" alt="">
      <span>快去新增账号吧~</span>
    </div>

    <div class="v-bind-account-list-btn">
      <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import { apiGetAccountList, apiSelectAccount } from '@/utils/api.js'
  export default {
    components: { HeadBack, OperationButton },
    data() {
      return {
        // 平台id
        platformId: this.$route.params.id,
        
        titleName: '淘宝/天猫 账号',

        // 是天猫还是京东还是拼多多
        routeType: this.$route.params.type,

        buttonObj: {
          claSS: 'c-btn-default',
          title: '新增账号',
          event: 'addCountClick'
        },

        platformList: [],

        accoutList: [],

        selectIndex: -1,
      }
    },

    created() {
      if(this.routeType === '0') {
        this.titleName = '淘宝/天猫账号'
      } else if(this.routeType === '1') {
        this.titleName = '京东账号'
      } else {
        this.titleName = '拼多多账号'
      }
      this.apiGetAccountListHandel()
    },

    methods: {
      // 获取平台账号列表
      async apiGetAccountListHandel() {
        const { success, data } = await apiGetAccountList({ platform_id: this.platformId })
        if(!success) return
        this.accoutList = data.list
        let obj = data.list.find(item => item.active === 1)
        if(!obj) this.selectIndex = -1
        if(obj) this.selectIndex = data.list.indexOf(obj)
        console.log('选中的', obj)
      },

      // 点击编辑
      editClick(item) {
        console.log('编辑')
        this.$router.push({ name: 'bindaccount', params: { type: this.routeType, platid: this.platformId, id: item.id } })
      },
      // 点击新增
      addCountClick() {
        console.log('this.userInfo', this.userInfo)
        if(this.userInfo.real_status != 2) return this.$toast('实名审核通过后才能绑定账号哦~')
        this.$router.push({ name: 'bindaccount', params: { type: this.routeType, platid: this.platformId, id: '0' } })
      },
      // 点击某一项
      itemClick(item, index) {
        if(this.selectIndex === index) return
        this.$dialog.confirm({
          message: '是否切换该账号进行接单?',
          width: '220px',
          theme: 'round',
          cancelButtonColor: '#333333',
          confirmButtonColor: '#F9436B',
        }).then(() => {
            if(item.status != 2) return this.$toast('该账号审核未通过,不能接单')
            this.apiSelectAccountHandel(item, index)
        }).catch(() => {});
      },

      // 切换账号接单
      async apiSelectAccountHandel(item, index) {
        const { success, data } = await apiSelectAccount({ id: item.id })
        if(!success) return
        this.$toast(data.msg)
        this.selectIndex = index
        console.log(data)
      },

      emitClickItem(event) {
        if(!event) return
        this[event]()
      }
    },
    computed: {
      userInfo() {
        return this.$global.userInfo
      }
    }
  }
</script>

<style lang="scss">
.v_bind_account_list {
  height: 100%;
  background: #F1F1F1;
  .v-bind-account-list {
    flex: 1;
    overflow: auto;
    padding: 0px 12px 10px 12px;
    // 项
    .v-bind-account-item {
      margin-top: 10px;
      padding: 0 10px 10px 12px;
      background: $white;
      border-radius: 12px;
      // 左侧
      .v-bind-account-item-left {
        padding-top: 8px;
        .iconfont {
          font-size: 24px;
          &.icon-xianshi_xuanzetianchong {
            color: $main_color;
          }
          &.icon-yuancircle46 {
            color: #919191;
          }

        }
      }
      // 右侧
      .v-bind-account-item-right {
        flex: 1;
        padding-left: 12px;
        padding-top: 12px;
        .v-bind-account-item-top {
          .v-bind-account-item-top-left {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
          }
          .v-bind-account-item-top-right {
            font-size: 14px;
            color: $main_color;
            padding-right: 8px;
          }
        }

        .v-bind-account-item-bottom {
          padding-top: 6px;
          .v-bind-account-item-bottom-left {
            flex: 1;
            p {
              flex: 1;
              font-size: 12px;
              color: #040303;
              padding-top: 10px;
              span {
                padding-left: 6px;
                flex: 1;
                @include textManyOverflow();
              }
            }
          }

          .v-bind-account-item-bottom-right {
            padding: 10px;
            .iconfont {
              font-size: 26px;
              color: $main_color;
            }
          }
        }
      }
    }
  }

  .v-bind-account-no-list {
    flex: 1;
    padding: 0px 12px 10px 12px;
    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }
    span {
      color: $grey;
      padding-top: 30px;
      font-size: 14px;
    }
  }

  .v-bind-account-list-btn {
    padding-top: 5px;
    padding-bottom: 20px;
  }
}
</style>